<template>
  <b-row class="animated fadeIn">
    <b-col v-if="policy_loaded">


        <div class="card mb-3">
          <div class="card-header">
            <b>{{ stageText() }}</b> {{model.policy_number}}
            <span class="float-right">
              <span><b>Last Modified:</b> {{ extras.created_at }}</span>
              <span class="pl-4"><b>Version: </b>{{ extras.version }}</span>
            </span>
          </div>
        </div>

        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>


        <div v-show="stage === 'decline'">

          <div id="decline" class="card border-danger">
            <div class="card-header bg-danger border-danger">

                <span class="text-white font-weight-bolder">
                <i class="fa fa-exclamation-triangle"></i>
                 &nbsp;
                {{ error_heading }}

                </span>

            </div>
            <div class="card-body border-danger text-dark font-weight-bold">

              {{ error_text }}

            </div>
          </div>

        </div>

        <div v-show="refer === 'true' || refer === true">

          <div id="refer" class="card border-info">
            <div class="card-header bg-info border-info">

                <span class="text-white font-weight-bolder">
                <i class="fa fa-exclamation-triangle"></i>
                 &nbsp;
                {{refer_heading}}

                </span>

            </div>
            <div class="card-body border-info text-dark font-weight-bold">

              <div class="row">

                <div class="col-4">

                  <p>For the following reason/s.</p>

                  <ul>
                    <li v-for="reason in refer_reason">{{ reason }}</li>
                  </ul>

                </div>

                <div class="col-4">

                    <p class="text-info">Previous Reason/s for refer:</p>

                    <ul>
                      <li  class="text-info" v-for="(reason, rindex) in previous_refer_data.reasons" :key="rindex">{{reason}}</li>
                    </ul>

                </div>

                <div class="col-4">

                    <p class="text-info">Previous refer overrider/s:</p>

                    <p v-show="previous_refer_data.load_override" class="mb-2">
                      <span v-show="previous_refer_data.buildings_load" class="text-info"><b>Buildings Load</b> {{ previous_refer_data.buildings_load }}%</span> <br v-show="previous_refer_data.buildings_load && previous_refer_data.contents_load">
                      <span v-show="previous_refer_data.contents_load" class="text-info"><b>Contents Load</b> {{ previous_refer_data.contents_load }}%</span>
                    </p>

                    <p v-show="previous_refer_data.premium_override" class="mb-2">
                      <span v-show="previous_refer_data.premium" class="text-info"><b>Premium Override</b> &pound;{{ previous_refer_data.premium }}</span>
                    </p>

                    <p v-show="previous_refer_data.manual_endorsements" class="mb-2">
                      <span v-show="previous_refer_data.manual_endorsements_list" class="text-info"><b class="text-info">Manual Endorsements:</b> <span v-for="(endorsement, index) in previous_refer_data.manual_endorsements_list" class="mr-2 text-info" :key="index">EN{{endorsement}}</span></span>
                    </p>


                </div>
              </div>


            </div>
          </div>

        </div>


        <div v-show="stage === 'success'">

          <div class="card border-success">
            <div class="card-header bg-success border-success">

                <span class="text-white font-weight-bolder">
                <i class="fa fa-check"></i>
                  &nbsp;
                Policy Updated
                </span>

            </div>
            <div class="card-body border-danger text-dark font-weight-bold">

              {{ success_text }}

            </div>
          </div>

        </div>

        <!-- START MTA Quote -->

        <div v-show="stage === 'quote'">

          <b-form @submit.stop.prevent="submitMTAQuote">

          <b-card header="General Details" class="mb-3">

            <b-form-row class="mb-3">

              <b-col cols="4">
                <label class="">Mortgage Provider</label>
                <b-input v-model="model.mortgage.provider" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
              </b-col>

              <b-col cols="4">
                <label class="">Mortgage Roll Number</label>
                <b-input v-model="model.mortgage.roll_number" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
              </b-col>

              <b-col cols="4">
                <label class="">Third Party Reference</label>
                <b-input v-model="model.third_party_reference" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
              </b-col>

            </b-form-row>

            <b-form-row>

              <b-col cols="4">
                <label class="">Start Date</label>
                <b-form-datepicker v-model="model.start_date" class="mb-2 mr-sm-2 mb-sm-0" :readonly="!model.payment.start_date_override" :state="validateState('start_date')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en-GB"  @input="changeStartDate"></b-form-datepicker>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <b-row>
                  <b-col cols="4">
                    <b-form-checkbox v-model="model.payment.start_date_override" class="mt-2">Override?</b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="model.payment.start_date_use_on_pdf" class="mt-2">Use on PDF?</b-form-checkbox>
                  </b-col>
                </b-row>

              </b-col>

              <b-col cols="4">
                <label class="">End Date</label>
                <b-form-datepicker v-model="model.end_date" class="mb-2 mr-sm-2 mb-sm-0" readonly :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
              </b-col>

              <b-col cols="4">
                <label class="">Effective Date</label>
                <b-form-datepicker v-model="model.effective_date" class="mb-2 mr-sm-2 mb-sm-0" :readonly="!model.payment.effective_date_override" :state="validateState('effective_date')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <b-form-checkbox v-model="model.payment.effective_date_override" class="mt-2">Override?</b-form-checkbox>

              </b-col>

            </b-form-row>

            <b-form-row v-show="!model.payment.annual || model.payment.annual === 'false' || model.payment.annual === false">

              <b-col cols="4" class="offset-4">

                <label class="">Direct Debit Months remaining</label>
                <b-form-select v-model="model.payment.direct_debit_months" :options="options.direct_debit_months"  :state="validateNestedState('payment', 'direct_debit_months')"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

            </b-form-row>

          </b-card>


          <div class="card mb-3" v-for="(v, index) in $v.model.applicants.$each.$iter" :v-bind="index" >

            <div class="card-header">

              <p class="float-left pt-1 mb-0" v-if="getIndex(index) === 1">Policy Holder Details</p>

              <p class="float-left pt-1 mb-0" v-show="getIndex(index) === 2">Secondary Applicant</p>

              <b-button variant="outline-danger" class=" btn-sm float-right" v-show="model.applicants.length > 1 && getIndex(index) === 2"
                        @click="removeApplicant">
                <i class="fa fa-times"></i> Remove Applicant
              </b-button>

              <b-button variant="outline-success" class=" btn-sm float-right" v-show="model.applicants.length === 1"
                        @click="addApplicant">
                <i class="fa fa-plus"></i> Add Applicant
              </b-button>
            </div>

            <div class="card-body">

              <b-form-row>

                <b-col cols="12" class="p-0">

                  <b-form-row>

                    <b-col cols="2">
                      <label class="">Title</label>
                      <b-form-select v-model="v.title.$model" :options="options.titles" :state="validateApplicantState(index, 'title')">
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                      </b-form-select>
                    </b-col>

                    <b-col cols="3">
                      <label class="">Forenames</label>
                      <b-input v-model="v.forename.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateApplicantState(index, 'forename')"></b-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-col>

                    <b-col cols="4">
                      <label class="">Surname</label>
                      <b-input v-model="v.surname.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateApplicantState(index, 'surname')"></b-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-col>

                    <b-col cols="3">
                      <label class="">Date of Birth</label>
                      <b-input v-model="v.date_of_birth.$model"  class="mb-2 mr-sm-2 mb-sm-0" type="tel" v-mask="'##/##/####'" :state="validateApplicantState(index, 'date_of_birth')"></b-input>
                      <!--<b-form-datepicker v-model="v.date_of_birth.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateApplicantState(index, 'date_of_birth')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>-->
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-col>

                  </b-form-row>

                  <b-form-row class="mt-2" v-show="getIndex(index) === 1">

                    <b-col cols="6">
                      <label class="">Email</label>
                      <b-input v-model="v.email.$model" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
                    </b-col>

                    <b-col cols="6">
                      <label class="">Telephone</label>
                      <b-input v-model="v.telephone.$model" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
                    </b-col>

                  </b-form-row>

                </b-col>

              </b-form-row>

            </div>
          </div>

          <b-card header="Property Details" class="mb-3">

            <b-form-row>

              <b-col cols="4" class="pr-3">

                <h6 class="mb-3">Property Specific</h6>

                <label class="">Property Type</label>
                <b-form-select v-model="model.property.property_type" :options="options.property_types" required
                               class="mb-3"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <label class="">Bedrooms</label>
                <b-form-select v-model="model.property.bedrooms" :options="options.bedrooms" required
                               class="mb-3"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <label class="">Year Built</label>
                <b-input v-model="model.property.year_built" class="mb-3"></b-input>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

              <b-col>

                <b-row>

                  <b-col cols="12">

                    <label class="">Is risk address correspondence address?</label>
                    <b-form-select v-model="extras.address_match" :options="options.yes_no" @change="addressMatch"
                                   class="mb-3"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="6">
                    <h6 class="mb-3">Correspondence Address</h6>

                    <label class="">Address 1</label>
                    <b-input v-model="$v.model.correspondence_address.address1.$model" class="mb-3" @input="addressUpdate" :state="validateNestedState('correspondence_address', 'address1')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                    <label class="">Address 2</label>
                    <b-input v-model="model.correspondence_address.address2" class="mb-3" @input="addressUpdate"></b-input>

                    <label class="">Address 3</label>
                    <b-input v-model="model.correspondence_address.address3" class="mb-3" @input="addressUpdate"></b-input>

                    <label class="">Address 4</label>
                    <b-input v-model="model.correspondence_address.address4" class="mb-3" @input="addressUpdate"></b-input>

                    <label class="">Postcode</label>
                    <b-input v-model="$v.model.correspondence_address.postcode.$model" class="mb-3" @input="addressUpdate" :state="validateNestedState('correspondence_address', 'postcode')"></b-input>
                    <b-form-invalid-feedback >This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="6" class="pr-3">
                    <h6 class="mb-3">Property Address</h6>

                    <label class="">Address 1</label>
                    <b-input v-model="model.property.address.address1" class="mb-3" @input="corresponenceAddressUpdate" :state="validateNestedNestedState('property', 'address', 'address1')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                    <label class="">Address 2</label>
                    <b-input v-model="model.property.address.address2" class="mb-3" @input="corresponenceAddressUpdate"></b-input>

                    <label class="">Address 3</label>
                    <b-input v-model="model.property.address.address3" class="mb-3" @input="corresponenceAddressUpdate"></b-input>

                    <label class="">Address 4</label>
                    <b-input v-model="model.property.address.address4" class="mb-3" @input="corresponenceAddressUpdate"></b-input>

                    <label class="">Postcode</label>
                    <b-input v-model="model.property.address.postcode" class="mb-3" @input="corresponenceAddressUpdate" :state="validateNestedNestedState('property', 'address', 'postcode')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                </b-row>

              </b-col>

            </b-form-row>

          </b-card>


          <b-card header="Agency Details" class="mb-3">

            <b-form-row>

              <b-col cols="4">

                <label class="">Broker Name</label>
                <b-input v-model="model.agency.broker_name"></b-input>

              </b-col>

              <b-col cols="4">

                <label class="">Contact Name</label>
                <b-input v-model="model.agency.contact_name"></b-input>
              </b-col>

              <b-col cols="4">

                <label class="">Firm FSA Reference</label>
                <b-input v-model="model.agency.firm_fsa_reference"></b-input>

              </b-col>

            </b-form-row>

          </b-card>


            <b-alert variant="danger" :show="!covers.buildings_cover & !covers.contents_cover" >
              <i class="fa fa-exclamation-circle"></i>&nbsp;Buildings Cover or Contents Cover is required.
            </b-alert>


          <div class="card mb-3">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">Buildings Cover</p>
              <b-button variant="outline-danger" class=" btn-sm float-right" v-show="covers.buildings_cover"
                        @click="removeCover('buildings_cover')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right" v-show="!covers.buildings_cover"
                        @click="addCover('buildings_cover')">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body" v-show="covers.buildings_cover">

              <b-form-row>

                <b-col>

                  <label class="">Cover Amount</label>
                  <b-form-select v-model="model.buildings_cover.cover_amount" :options="options.buildings_cover_amounts"
                                 class="mb-3" :state="validateNestedState('buildings_cover', 'cover_amount')"></b-form-select>
                  <b-form-invalid-feedback >This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Excess</label>
                  <b-form-select v-model="model.buildings_cover.excess" :options="options.cover_excess"
                                 class="mb-3" :state="validateNestedState('buildings_cover', 'excess')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Claim Free Years</label>
                  <b-form-select v-model="model.buildings_cover.claim_free_years"
                                 :options="options.cover_claim_free_years" class="mb-3" :state="validateNestedState('buildings_cover', 'claim_free_years')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Accidental Damage</label>
                  <b-form-select v-model="model.buildings_cover.accidental_damage" :options="options.yes_no"
                                 class="mb-3" :state="validateNestedState('buildings_cover', 'accidental_damage')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

              </b-form-row>

            </div>
          </div>


          <div class="card mb-3">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">Contents Cover</p>
              <b-button variant="outline-danger" class=" btn-sm float-right" v-show="covers.contents_cover"
                        @click="removeCover('contents_cover')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right" v-show="!covers.contents_cover"
                        @click="addCover('contents_cover')">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body" v-show="covers.contents_cover">

              <b-form-row>

                <b-col>

                  <label class="">Cover Amount</label>
                  <b-form-select v-model="model.contents_cover.cover_amount" :options="options.contents_cover_amounts" class="mb-3" :state="validateNestedState('contents_cover', 'cover_amount')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Excess</label>
                  <b-form-select v-model="model.contents_cover.excess" :options="options.cover_excess" class="mb-3" :state="validateNestedState('contents_cover', 'excess')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Claim Free Years</label>
                  <b-form-select v-model="model.contents_cover.claim_free_years" :options="options.cover_claim_free_years" class="mb-3" :state="validateNestedState('contents_cover', 'claim_free_years')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Accidental Damage</label>
                  <b-form-select v-model="model.contents_cover.accidental_damage" :options="options.yes_no" class="mb-3" :state="validateNestedState('contents_cover', 'accidental_damage')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Unspecified Cover Amount</label>
                  <b-form-select v-model="model.contents_cover.unspecified_cover_amount" :options="options.unspecified_cover_amounts" class="mb-3"></b-form-select>

                </b-col>

              </b-form-row>

            </div>
          </div>


          <div class="card mb-3" v-show="covers.contents_cover">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">High risk items away from the home</p>
              <b-button variant="outline-danger" class=" btn-sm float-right"
                        v-show="model.contents_cover.specified_items.length > 0 && model.contents_cover"
                        @click="removeCover('specified_items')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right"
                        v-show="model.contents_cover.specified_items.length <= 0 && model.contents_cover"
                        @click="addSpecifiedItem">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body" v-show="notEmptyObject(model.contents_cover.specified_items) && model.contents_cover">

              <b-form-row v-if="model.contents_cover.specified_items.length > 0" v-for="(item, index) in model.contents_cover.specified_items" :key="index">

                <b-col cols="3">

                  <label class="">Type</label>
                  <b-form-select v-model="item.type" :options="options.specified_item_types" class="mb-3" :state="validateItemState('specified_items', index, 'type')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Description</label>
                  <b-input v-model="item.description" :state="validateItemState('specified_items', index, 'description')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="2">

                  <label class="">Value (&pound;)</label>
                  <b-input v-model="item.value" type="number" :state="validateItemState('specified_items', index, 'value')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="1" class="text-center">

                  <label class="">&nbsp;</label> <br>

                  <b-button variant="outline-danger" class="" @click="deleteSpecifiedItem(index)">
                    <i class="fa fa-times"></i>
                  </b-button>

                </b-col>

              </b-form-row>


            </div>

            <div class="card-footer bg-white"
                 v-show="notEmptyObject(model.contents_cover.specified_items) && model.contents_cover">

              <b-button variant="outline-success" class="" @click="addSpecifiedItem">
                <i class="fa fa-plus"></i> Add Item
              </b-button>

            </div>

          </div>


          <div class="card mb-3" v-show="covers.contents_cover">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">High risk items within the home</p>
              <b-button variant="outline-danger" class=" btn-sm float-right"
                        v-show="model.contents_cover.high_risk_items.length > 0 && model.contents_cover"
                        @click="removeCover('high_risk_items')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right"
                        v-show="model.contents_cover.high_risk_items.length <= 0 && model.contents_cover"
                        @click="addHighRiskItem">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body"
                 v-show="covers.contents_cover && notEmptyObject(model.contents_cover.high_risk_items) && model.contents_cover">

              <b-form-row v-if="model.contents_cover.high_risk_items.length > 0"
                          v-for="(item, index) in model.contents_cover.high_risk_items" :key="index">

                <b-col cols="3">

                  <label class="">Type</label>
                  <b-form-select v-model="item.type" :options="options.high_risk_item_types" class="mb-3" :state="validateItemState('high_risk_items', index, 'type')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Description</label>
                  <b-input v-model="item.description" :state="validateItemState('high_risk_items', index, 'description')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="2">

                  <label class="">Value (&pound;)</label>
                  <b-input v-model="item.value" type="number" :state="validateItemState('high_risk_items', index, 'value')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="1" class="text-center">

                  <label class="">&nbsp;</label> <br>

                  <b-button variant="outline-danger" class="" @click="deleteHighRiskItem(index)">
                    <i class="fa fa-times"></i>
                  </b-button>

                </b-col>

              </b-form-row>

            </div>

            <div class="card-footer bg-white"
                 v-show="covers.contents_cover && notEmptyObject(model.contents_cover.high_risk_items) ">

              <b-button variant="outline-success" class="" @click="addHighRiskItem">
                <i class="fa fa-plus"></i> Add Item
              </b-button>

            </div>
          </div>


          <b-card header="Bank Details" class="mb-3">

            <b-form-row>

              <b-col cols="3">

                <label class="">Account Name</label>
                <b-input v-model="$v.model.payment.account_name.$model" :state="validateNestedState('payment', 'account_name')"></b-input>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

              <b-col cols="3">

                <label class="">Account Number</label>
                <b-input v-model="$v.model.payment.account_number.$model" :state="validateNestedState('payment', 'account_number')"></b-input>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
              </b-col>

              <b-col cols="3">

                <label class="">Account Sort Code</label>
                <b-input v-model="$v.model.payment.sort_code.$model" :state="validateNestedState('payment', 'sort_code')" ></b-input>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

              <b-col cols="3">

                <label class="">Payment Type</label>
                <b-form-select v-model="model.payment.annual" :options="options.payment_types" :state="validateNestedState('payment', 'annual')"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

            </b-form-row>

          </b-card>


          <b-card header="Addons" class="mb-3">

            <b-form-row>

              <b-col cols="3">

                <label class="">Home Emergency Cover</label>
                <b-form-select v-model="model.optional_extras.hec" :options="options.yes_no" required></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

              <b-col cols="3">

                <label class="">Family Legal Cover</label>
                <b-form-select v-model="model.optional_extras.lah" :options="options.yes_no" required></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
              </b-col>

            </b-form-row>

          </b-card>


          <b-card header="Non Standard" class="mb-3">


            <b-form-row>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.construction_listed_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor mb-2">
                  Property Construction/Listed
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.business_use_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor mb-2">
                  Business Use At Property
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.occupancy_holiday_home_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor mb-2">
                  Occupancy/Holiday Home
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.building_work_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor mb-2">
                  Building Works
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.subsidence_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor mb-2">
                  Previous Subsidence
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.claim_history_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor mb-2">
                  Claims History
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.flooding_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor">
                  Previous Flooding
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.criminal_conviction_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor">
                  Criminal Convictions
                </b-form-checkbox>
              </b-col>

              <b-col cols="4">
                <b-form-checkbox v-model="model.non_standard.cancelled_declined_bool" button button-variant="outline-dark btn-block py-2 w-100 hand-cursor" class="w-100 hand-cursor">
                  Insurance Cancelled/Declined
                </b-form-checkbox>
              </b-col>

              <b-col cols="12" class="my-3" v-show="model.non_standard.construction_listed_bool">

                <h5 class="mb-3">Property Construction / Listed Building</h5>

                <!--
                <b-form-group >
                  <label for="" class="mr-4">Is the home registered as a listed building?</label>
                  <b-form-radio-group
                    v-model="model.non_standard.construction_listed.listed_building"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>
                -->

                <div class="row my-3" >
                  <div class="col">
                  <label for="" class="mr-4">Is the home registered as a listed building?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.construction_listed.listed_building" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'construction_listed', 'listed_building')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <!--

                <b-form-group >
                  <label for="" class="mr-4">Does the home have a roof which is built from anything other than tiles or slate?</label>

                  <b-form-radio-group
                    v-model="model.non_standard.construction_listed.roof_type_question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                <div class="row my-3" >
                  <div class="col">
                  <label for="" class="mr-4">Does the home have a roof which is built from anything other than tiles or slate?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.construction_listed.roof_type_question" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'construction_listed', 'roof_type_question')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.construction_listed.roof_type_question === true || model.non_standard.construction_listed.roof_type_question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What material is the roof of your property built from?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.construction_listed.roof_type" :options="options.non_standard.construction_listed.roof_type" :state="validateNestedNestedState('non_standard', 'construction_listed', 'roof_type')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <!--

                <b-form-group >
                  <label for="" class="mr-4">Does the home have a roof which is more than 50% flat?</label>
                  <b-form-radio-group
                    v-model="model.non_standard.construction_listed.flat_roof"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                <div class="row my-3" >
                  <div class="col">
                  <label for="" class="mr-4">Does the home have a roof which is more than 50% flat?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.construction_listed.flat_roof" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'construction_listed', 'flat_roof')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <!--
                <b-form-group >
                  <label for="" class="mr-4">Does the home have walls which are built from anything other than brick, stone or concrete?</label>
                  <b-form-radio-group
                    v-model="model.non_standard.construction_listed.wall_type_question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>
                -->

                <div class="row my-3" >
                  <div class="col">
                  <label for="" class="mr-4">Does the home have walls which are built from anything other than brick, stone or concrete?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.construction_listed.wall_type_question" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'construction_listed', 'wall_type_question')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.construction_listed.wall_type_question === true || model.non_standard.construction_listed.wall_type_question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What material are the walls of your property built from?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.construction_listed.wall_type" :options="options.non_standard.construction_listed.wall_type" :state="validateNestedNestedState('non_standard', 'construction_listed', 'wall_type')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

              </b-col>


              <b-col cols="12" class="my-3" v-show="model.non_standard.business_use_bool">

                <h5 class="mb-3">Business Use At Property</h5>

                <!--
                <b-form-group >
                  <label for="" class="mr-4">
                    Will the Home: <br>
                    <ul>
                      <li>be used for business purposes other than clerical work, private tuition, hairdressing, therapy or beauty treatment; or</li>
                      <li>be visited more than 10 times per month in connection to business use; or</li>
                      <li>have more than £2,000 in stock held on the premises?</li>
                    </ul>

                  </label>

                  <b-form-radio-group
                    v-model="model.non_standard.business_use.question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                    @change="toggleNonStandardSection($event, 'business_use')"
                  ></b-form-radio-group>
                </b-form-group>

                  -->

                <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">
                    Will the Home: <br>
                    <ul>
                      <li>be used for business purposes other than clerical work, private tuition, hairdressing, therapy or beauty treatment; or</li>
                      <li>be visited more than 10 times per month in connection to business use; or</li>
                      <li>have more than £2,000 in stock held on the premises?</li>
                    </ul>

                  </label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.business_use.question" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'business_use', 'question')" @change="toggleNonStandardSection($event, 'business_use')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.business_use.question === true || model.non_standard.business_use.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What type of business is carried out at the property?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.business_use.business_type" :options="options.non_standard.business_use.business_type"  :state="validateNestedNestedState('non_standard', 'business_use', 'business_type')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <!--

                <b-form-group v-show="model.non_standard.business_use.question === true || model.non_standard.business_use.question === 'true'">
                  <label for="" class="mr-4">Is the property visited more than 200 times per month in connection to the business use?</label>
                  <b-form-radio-group
                    v-model="model.non_standard.business_use.business_visitors"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                <div class="row my-3" v-show="model.non_standard.business_use.question === true || model.non_standard.business_use.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">Is the property visited more than 200 times per month in connection to the business use?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.business_use.business_visitors" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'business_use', 'business_visitors')" ></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>


                <div class="row my-3" v-show="model.non_standard.business_use.question === true || model.non_standard.business_use.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">Total cover for equipment and stock required?</label>
                  </div>
                  <div class="col">
                    <b-input-group size="md" prepend="£">
                      <b-form-input v-model="model.non_standard.business_use.business_stock_cover" :state="validateNestedNestedState('non_standard', 'business_use', 'business_stock_cover')"></b-form-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </div>

              </b-col>


              <b-col cols="12" class="my-3" v-show="model.non_standard.occupancy_holiday_home_bool">

                <h5 class="mb-3">Property Occupancy/Holiday Home</h5>

                <!--

                <b-form-group >
                  <label for="" class="mr-4">Will the home be occupied by anybody other than you and your family?</label>

                  <b-form-radio-group
                    v-model="model.non_standard.occupancy_holiday_home.question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                    @change="toggleNonStandardSection($event, 'occupancy_holiday_home')"
                  ></b-form-radio-group>
                </b-form-group>

                 -->

                <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">Will the home be occupied by anybody other than you and your family?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.occupancy_holiday_home.question" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'occupancy_holiday_home', 'question')" @change="toggleNonStandardOccupiedSection($event, 'question')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.occupancy_holiday_home.question === true || model.non_standard.occupancy_holiday_home.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What is the occupancy of the property?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.occupancy_holiday_home.occupancy_type" :options="options.non_standard.occupancy_holiday_home.occupancy_type" :state="validateNestedNestedState('non_standard', 'occupancy_holiday_home', 'occupancy_type')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>


                <div class="row my-3" v-show="model.non_standard.occupancy_holiday_home.occupancy_type === 4 || model.non_standard.occupancy_holiday_home.occupancy_type === '4'">
                  <div class="col">
                  <label for="" class="mr-4">Number of Lodgers?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.occupancy_holiday_home.number_of_lodgers" :options="options.non_standard.occupancy_holiday_home.number_of_lodgers" :state="validateNestedNestedState('non_standard', 'occupancy_holiday_home', 'number_of_lodgers')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <!--

                <b-form-group v-show="model.non_standard.occupancy_holiday_home.question === true || model.non_standard.occupancy_holiday_home.question === 'true'">
                  <label for="" class="mr-4">Will the home be left unoccupied for more than 60 days in a row?</label>

                  <b-form-radio-group
                    v-model="model.non_standard.occupancy_holiday_home.unoccupied_question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">Will the home be left unoccupied for more than 60 days in a row?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.occupancy_holiday_home.unoccupied_question" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'occupancy_holiday_home', 'unoccupied_question')" @change="toggleNonStandardOccupiedSection($event, 'unoccupied_question')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.occupancy_holiday_home.unoccupied_question === true || model.non_standard.occupancy_holiday_home.unoccupied_question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What is the total number of consecutive days the property will be unoccupied?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.occupancy_holiday_home.unoccupied_period" :options="options.non_standard.occupancy_holiday_home.unoccupied_period" :state="validateNestedNestedState('non_standard', 'occupancy_holiday_home', 'unoccupied_period')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

              </b-col>


              <b-col cols="12" class="my-3" v-show="model.non_standard.building_work_bool">

                <h5 class="mb-3">Building Works</h5>

                <!--

                <b-form-group >
                  <label for="" class="mr-4">Is the home undergoing or set to undergo any building work?</label>

                  <b-form-radio-group
                    v-model="model.non_standard.building_work.question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                    @change="toggleNonStandardSection($event, 'building_work')"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">Is the home undergoing or set to undergo any building work?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.building_work.question" :options="options.non_standard.yes_no"  :state="validateNestedNestedState('non_standard', 'building_work', 'question')" @change="toggleNonStandardSection($event, 'building_work')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>


                <div class="row my-3" v-show="model.non_standard.building_work.question === true || model.non_standard.building_work.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What is the nature of the building work?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.building_work.building_work_type" :options="options.non_standard.building_work.building_work_type"  :state="validateNestedNestedState('non_standard', 'building_work', 'building_work_type')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.building_work.question === true || model.non_standard.building_work.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What is the total value of the work?</label>
                  </div>
                  <div class="col">
                    <b-input-group size="md" prepend="£">
                      <b-form-input v-model="model.non_standard.building_work.building_work_value" :state="validateNestedNestedState('non_standard', 'building_work', 'building_work_value')"></b-form-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </div>

                <!--

                <b-form-group v-show="model.non_standard.building_work.question === true || model.non_standard.building_work.question === 'true'">
                  <label for="" class="mr-4">Will the property be unoccupied during the works?</label>

                  <b-form-radio-group
                    v-model="model.non_standard.building_work.building_work_occupancy"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                 <div class="row my-3"  v-show="model.non_standard.building_work.question === true || model.non_standard.building_work.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">Will the property be unoccupied during the works?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.building_work.building_work_occupancy" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'building_work', 'building_work_occupancy')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

              </b-col>


              <b-col cols="12" class="my-3" v-show="model.non_standard.subsidence_bool">

                <h5 class="mb-3">Previous Subsidence</h5>

                <!--
                <b-form-group >
                  <label for="" class="mr-4">Has the home shown any signs of subsidence, heave or landslip within the last 25 years?</label>

                  <b-form-radio-group
                    v-model="model.non_standard.subsidence.subsidence_occured"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>
                -->

                 <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">Has the home shown any signs of subsidence, heave or landslip within the last 25 years?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.subsidence.subsidence_occured" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'subsidence', 'subsidence_occured')" @change="toggleNonStandardSection($event, 'subsidence')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>


                <div class="row my-3" v-show="model.non_standard.subsidence.subsidence_occured === true || model.non_standard.subsidence.subsidence_occured  === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">How long have you owned the property?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.subsidence.property_owned_period" :options="options.non_standard.subsidence.property_owned_period" :state="validateNestedNestedState('non_standard', 'subsidence', 'property_owned_period')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

              </b-col>

              <b-col cols="12" class="my-3" v-show="model.non_standard.claim_history_bool">

                <h5 class="mb-3">Claims History</h5>

                <!--
                <b-form-group >
                  <label for="" class="mr-4">
                    In the last 3 years have you (or anyone living with you) had: <br>
                    <ul>
                      <li>more than one household loss or claim; or</li>
                      <li>any single loss or claim costing more than £3,000?</li>
                    </ul>

                  </label>

                  <b-form-radio-group
                    v-model="model.non_standard.claim_history"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                    @change="toggleNonStandardSection($event, 'claim_history')"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                 <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">
                    In the last 3 years have you (or anyone living with you) had: <br>
                    <ul>
                      <li>more than one household loss or claim; or</li>
                      <li>any single loss or claim costing more than £3,000?</li>
                    </ul>
                  </label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.claim_history" :options="options.non_standard.yes_no" :state="validateNestedState('non_standard', 'claim_history')" @change="toggleNonStandardSection($event, 'claim_history')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>


                <b-form-row v-if="model.claims.length > 0" v-for="(item, index) in model.claims" :key="index" class=" my-3">

                  <b-col cols="2">

                    <label class="">Date of claim</label>
                    <b-form-datepicker v-model="item.date" class="mb-2 mr-sm-2 mb-sm-0" :state="validateClaimItemState(index, 'date')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="5">

                    <label class="">Type of claim</label>
                    <b-form-select v-model="item.type" :options="options.non_standard.claim_history.type" :state="validateClaimItemState(index, 'type')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </b-col>

                  <b-col cols="2">

                    <label class="">Cost of claim</label>
                    <b-input-group size="md" prepend="£">
                      <b-form-input v-model="item.cost" :state="validateClaimItemState(index, 'cost')"></b-form-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-input-group>

                  </b-col>

                  <b-col cols="2">

                    <label class="">Status of claim</label>
                    <b-form-select v-model="item.status" :options="options.non_standard.claim_history.status" :state="validateClaimItemState(index, 'status')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="1" class="text-center">

                    <label class="">&nbsp;</label> <br>

                    <b-button variant="outline-danger" class="" @click="deleteClaimItem(index)">
                      <i class="fa fa-times"></i>
                    </b-button>

                  </b-col>

                </b-form-row>

                <b-button variant="outline-success" class="" @click="addClaimItem" v-show="model.non_standard.claim_history === true || model.non_standard.claim_history === 'true'">
                  <i class="fa fa-plus"></i> Add Claim
                </b-button>

              </b-col>



              <b-col cols="12" class="my-3" v-show="model.non_standard.flooding_bool">

                <h5 class="mb-3">Previous Flooding</h5>

                <!--

                <b-form-group >
                  <label for="" class="mr-4">
                    Does the home have a history of flooding or have you have ever undertaken preventative measures to limit flooding?
                  </label>

                  <b-form-radio-group
                    v-model="model.non_standard.flooding.question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                    @change="toggleNonStandardSection($event, 'flooding')"
                  ></b-form-radio-group>
                </b-form-group>

                -->

                <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">Does the home have a history of flooding or have you have ever undertaken preventative measures to limit flooding?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.flooding.question" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'flooding', 'question')" @change="toggleNonStandardSection($event, 'flooding')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.flooding.question === true || model.non_standard.flooding.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What year did the property last flood?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.flooding.property_flood_year" :options="options.non_standard.flooding.property_flood_year" :state="validateNestedNestedState('non_standard', 'flooding', 'property_flood_year')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.flooding.question === true || model.non_standard.flooding.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What year did the area last flood?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.flooding.area_flood_year" :options="options.non_standard.flooding.area_flood_year" :state="validateNestedNestedState('non_standard', 'flooding', 'area_flood_year')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

              </b-col>


              <b-col cols="12" class="my-3" v-show="model.non_standard.criminal_conviction_bool">

                <h5 class="mb-3">Unspent Criminal Convictions</h5>

                <!--

                <b-form-group >
                  <label for="" class="mr-4">
                    Have you or anyone living with you been convicted or charged for any offence or have any prosecution pending (other than a motoring offence)?
                  </label>

                  <b-form-radio-group
                    v-model="model.non_standard.criminal_conviction"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="radio-btn-outline"
                    @change="toggleNonStandardSection($event, 'criminal_conviction')"
                  ></b-form-radio-group>
                </b-form-group>

                -->


                 <div class="row my-3">
                  <div class="col">
                  <label for="" class="mr-4">
                    Have you or anyone living with you been convicted or charged for any offence or have any prosecution pending (other than a motoring offence)?
                  </label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.criminal_conviction" :options="options.non_standard.yes_no" :state="validateNestedState('non_standard', 'criminal_conviction')" @change="toggleNonStandardSection($event, 'criminal_conviction')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>


                <b-form-row v-if="model.convictions.length > 0" v-for="(item, index) in model.convictions" :key="index" class=" my-3">

                  <b-col cols="4" class="mb-3">

                    <label class="">Is the conviction pending</label>
                    <b-form-select v-model="item.pending" :options="options.non_standard.yes_no" :state="validateConvictionItemState(index, 'pending')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="4" class="mb-3">

                    <label class="">Date of conviction</label>
                    <b-form-datepicker v-model="item.date" class="mb-2 mr-sm-2 mb-sm-0" :state="validateConvictionItemState(index, 'date')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </b-col>

                  <b-col cols="4" class="mb-3">

                    <label class="">What was the conviction for</label>
                    <b-form-select v-model="item.type" :options="options.non_standard.criminal_conviction.type" :state="validateConvictionItemState(index, 'type')"></b-form-select>

                  </b-col>

                  <b-col cols="4">

                    <label class="">What sentence was given</label>
                    <b-form-select v-model="item.sentence" :options="options.non_standard.criminal_conviction.sentence" :state="validateConvictionItemState(index, 'sentence')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="3">

                    <label class="">Age of offender</label>
                    <b-input type="number" v-model="item.age" :state="validateConvictionItemState(index, 'age')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="4">

                    <label class="">What is the offenders employment type</label>
                    <b-form-select v-model="item.employment" :options="options.non_standard.criminal_conviction.employment" :state="validateConvictionItemState(index, 'employment')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="1" class="text-center">

                    <label class="">&nbsp;</label> <br>

                    <b-button variant="outline-danger" class="" @click="deleteConvictionItem(index)">
                      <i class="fa fa-times"></i>
                    </b-button>

                  </b-col>

                </b-form-row>

                <b-button variant="outline-success" class="" @click="addConvictionItem" v-show="model.non_standard.criminal_conviction === true || model.non_standard.criminal_conviction === 'true'">
                  <i class="fa fa-plus"></i> Add Conviction
                </b-button>

              </b-col>



              <b-col cols="12" class="my-3" v-show="model.non_standard.cancelled_declined_bool">

                <h5 class="mb-3">Previous Insurance Cancelled or Declined/Refused</h5>

                <!--
                <b-form-group >
                  <label for="" class="mr-4">
                    Have you or anyone living with you had special terms imposed by your insurer, or had insurance declined, cancelled, refused or declared void?
                  </label>

                  <b-form-radio-group
                    v-model="model.non_standard.cancelled_declined.question"
                    :options="options.non_standard.yes_no"
                    buttons
                    button-variant="outline-dark"
                    size="md"
                    name="model.non_standard.cancelled_declined.question"
                    @change="toggleNonStandardSection($event, 'cancelled_declined')"
                    :state="validateNestedNestedState('non_standard', 'cancelled_declined', 'question')"
                  >
                  </b-form-radio-group>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                </b-form-group>
                -->

                <div class="row my-3" >
                  <div class="col">
                  <label for="" class="mr-4">Have you or anyone living with you had special terms imposed by your insurer, or had insurance declined, cancelled, refused or declared void?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.cancelled_declined.question" :options="options.non_standard.yes_no" :state="validateNestedNestedState('non_standard', 'cancelled_declined', 'question')" @change="toggleNonStandardSection($event, 'cancelled_declined')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.cancelled_declined.question === true || model.non_standard.cancelled_declined.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What happened?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.cancelled_declined.what_happened" :options="options.non_standard.cancelled_declined.what_happened" :state="validateNestedNestedState('non_standard', 'cancelled_declined', 'what_happened')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

                <div class="row my-3" v-show="model.non_standard.cancelled_declined.question === true || model.non_standard.cancelled_declined.question === 'true'">
                  <div class="col">
                  <label for="" class="mr-4">What was the reason?</label>
                  </div>
                  <div class="col">
                  <b-form-select v-model="model.non_standard.cancelled_declined.reason" :options="options.non_standard.cancelled_declined.reason"  :state="validateNestedNestedState('non_standard', 'cancelled_declined', 'reason')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </div>
                </div>

              </b-col>

            </b-form-row>

          </b-card>

          <div class="card">
            <div class="card-footer">
              <b-button type="submit" variant="success" class="float-right" @click="submitMTAQuote">Amend Policy</b-button>
            </div>
          </div>

          </b-form>

        </div>

        <div v-show="stage === 'refer'">

          <div class="card" v-show="refer === 'true' || refer === true">
            <div class="card-header">
              Refer Options
            </div>
            <div class="card-body">

              <b-alert variant="danger" :show="manual_endorsements.length === 0 && !model.contents_load && !model.buildings_load && !model.premium" >
                <i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;Please enter Premium Loading or Premium Override and/or Manual Endorsements
              </b-alert>

              <!-- Add error for refer options -->

                <div class="row">
                  <div class="col-4" v-show="covers.buildings_cover">

                    <label for="">Buildings Premium Load</label>
                    <b-input-group size="md" append="%">
                        <b-form-input type="number" v-model="model.buildings_load" placeholder="20"></b-form-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-input-group>

                  </div>
                  <div class="col-4"  v-show="covers.contents_cover">

                    <label for="">Contents Premium Load</label>
                    <b-input-group size="md" append="%">
                        <b-form-input type="number" v-model="model.contents_load" placeholder="20"></b-form-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-input-group>

                  </div>
                  <div class="col-4">

                    <label for="">Premium Override</label>
                    <b-input-group size="md" prepend="£">
                        <b-form-input type="number" v-model="model.premium" placeholder="100.00 (ex Admin Fee)"></b-form-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-input-group>

                  </div>
                </div>


                <div class="row">

                  <div class="col-6" v-show="existing_endorsements.length > 0">

                    <div class="row" >
                      <div class="col-12">
                        <h5 class="my-3">Existing Endorsements</h5>
                        <p v-for="(endorsement, index) in existing_endorsements" :key="index">EN{{endorsement.code}} - {{endorsement.title}}</p>
                      </div>
                    </div>

                  </div>

                  <div class="col-6">

                    <b-col cols="12" class="mt-3">
                      <h5>
                        Manual Endorsements
                      <b-button variant="outline-success" class="btn-sm pull-right" @click="addEndorsement">
                          <i class="fa fa-plus"></i> Add Endorsement
                      </b-button>
                      </h5>
                    </b-col>

                    <b-col v-if="manual_endorsements.length > 0" v-for="(endorsement, endindex) in manual_endorsements" cols="12" class="my-2 mb-3" :key="endindex">

                      <label class="w-100 clearfix">Please select a endorsement <b-button variant="btn-link" class="text-danger text-small btn-sm float-right" @click="removeEndorsement(index)">Remove</b-button></label>
                      <b-form-select v-model="endorsement.code" :options="all_endorsements" :selected="endorsement" :state="validateManualEndorsementState(endindex)"></b-form-select>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                    </b-col>

                    </div>

                </div>

            </div>
          </div>

          <div class="card">
            <div class="card-footer">
              <b-button type="submit" variant="success" class="float-right" @click="submitMTAQuote">Amend Policy</b-button>
            </div>
          </div>

        </div>


        <!-- START MTA Premium -->

        <div v-show="stage === 'premium'">

          <div class="card mb-3">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">Premium Differences</p>
              <b-button variant="outline-secondary" class=" btn-sm float-right" @click="switchMTAStage('quote')">
                <i class="fa fa-arrow-left"></i> &nbsp;Back to Quote
              </b-button>
            </div>

            <div class="card-body">

              <table class="table">

                <tr>
                  <th>
                    <b-button variant="outline-info" class=" btn-sm" @click="toggleShowBreakdown"><span v-if="!show_breakdown">Show</span> <span v-else>Hide</span> Breakdown</b-button>
                  </th>
                  <th v-show="overidden_premium">
                    Overidden
                  </th>
                  <th>
                    Adjusted
                  </th>
                  <th>
                    Original
                  </th>
                </tr>
                <tr>
                  <th colspan="4">
                    Monthly
                  </th>
                </tr>
                <tr>
                  <td>
                    Total Premium
                  </td>
                  <td v-show="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.monthly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.total_premium.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Discount
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.discount.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.monthly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].monthly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.monthly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.credit_charge.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Family Legal Expenses
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.family_legal_protection.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Home Emergency Cover
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.home_emergency_cover.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Policy Fee
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.policy_fee.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.monthly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].monthly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.monthly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (£)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.ipt.toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Premium
                  </td>
                  <td v-if="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.monthly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.premium.toFixed(2) }}
                  </td>
                </tr>

                <tr>
                  <th colspan="4">
                    Yearly
                  </th>
                </tr>
                <tr>
                  <td>
                    Total Premium
                  </td>
                  <td v-if="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.yearly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.total_premium.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Discount
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.discount.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.yearly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].yearly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.yearly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.credit_charge.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Family Legal Expenses
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.family_legal_protection.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Home Emergency Cover
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.home_emergency_cover.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Policy Fee
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.policy_fee.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.yearly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].yearly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.yearly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (£)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.ipt.toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Premium
                  </td>
                  <td v-if="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.yearly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.premium.toFixed(2) }}
                  </td>
                </tr>

                <tr>
                  <th>
                    Underwriter Premium
                  </th>
                  <td v-show="overidden_premium">
                    &pound;{{ overidden_premium_response.underwriter_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].underwriter_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ this.previous_underwriter_premium.toFixed(2) }}
                  </td>
                </tr>

              </table>

            </div>

          </div>

          <div class="card">
            <div class="card-header">
              Endorsements
            </div>
            <div class="card-body">
              <table class="table">
                <thead>
                <tr>
                  <th>Code</th>
                  <th>Title</th>
                </tr>
                </thead>
                <tbody>
                  <tr  v-for="(endorsement, index) in quote_response.insurers[0].full_endorsements" :key="index">
                    <td>EN{{ endorsement.code }}</td>
                    <td>{{ endorsement.title }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card" v-show="refer === 'false' || refer === false">
            <div class="card-footer">

              <b-form-row>
                <b-col class="col" v-show="override_permission">
                  <b-form-row>
                    <b-col cols="4">
                      <b-input-group size="md" prepend="£">
                        <b-form-input type="number" v-model="override_premium" placeholder="100.00 (ex Admin Fee)"></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col cols="6">
                      <b-button type="submit" variant="outline-success"  :disabled="override_premium <= 0"  @click="submitPremiumOverride" >Override Premium</b-button>
                      <b-button type="submit" variant="outline-danger" v-show="remove_premium_override" class="ml-2"  @click="removeOverridePremium" >Remove</b-button>
                    </b-col>
                  </b-form-row>
                </b-col>

                <b-col>
                  <b-button type="submit" variant="success" class="float-right" @click="submitMTAConfirm">Confirm & Process MTA Adjustment</b-button>
                </b-col>

              </b-form-row>

            </div>
          </div>

        </div>

        <!-- END MTA Premium -->

    </b-col>
  </b-row>
</template>

<script>
  import axios from '../../../shared/axios/auth'
  import MTAData from './mta_data'
  import MTAQuoteResponse from './mta_quote_response'
  import MTAOverridePremium from './mta_override_premium'
  import { validationMixin } from 'vuelidate'
  import { required, email, requiredIf, numeric, decimal } from "vuelidate/lib/validators";
  import { BFormDatepicker } from 'bootstrap-vue'
  import {mask} from 'vue-the-mask'
  import moment from 'moment'

  function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  const isDate = (value) => moment(value, 'DD/MM/YYYY', true).isValid()
  const isNotInFuture = (value) => moment(value, 'DD/MM/YYYY', true).isSameOrBefore(new Date());

  export default {
    components: {
      BFormDatepicker
    },
    directives: {mask},
    mixin: [validationMixin],
    data() {
      return {
        selected: null,
        test: false,
        error: false,
        refer: false,
        refer_heading: "Change/s on this policy resulted in a refer.",
        refer_reason: [],
        error_heading: 'There was a error',
        error_text: 'There was a error',
        success_text: 'Policy has been updated.',
        all_endorsements: [],
        success: false,
        mta_id: this.$route.params.id,
        policy_loaded: false,
        model: {},
        quote_response: MTAQuoteResponse,
        previous_premium_response: MTAQuoteResponse,
        previous_underwriter_premium: 0.00,
        overidden_premium_response: MTAOverridePremium,
        show_breakdown: false,
        overidden_premium: false,
        stage: 'quote',
        start_date_override: false,
        start_date_use_on_pdf: false,
        effective_date_override: false,
        effective_date: new Date(),
        override_permission: true,
        override_premium: null,
        remove_premium_override: false,
        extras: null,
        covers: null,
        specified_invividual_limit_min: 2000,
        specified_invividual_limit_max: 7000,
        specified_max_limit: 15000,
        previous_refer: false,
        previous_refer_data: {
          load_override: false,
          buildings_load: null,
          contents_load: null,
          premium_override: false,
          premium: null,
          manual_endorsements: false,
          manual_endorsements_list: [],
          reasons: []
        },
        manual_endorsements: [],
        existing_endorsements: []
      }
    },
    validations() {

      var standard_validations = {
        start_date: {
          required
        },
        effective_date: {
          required
        },
        end_date: {
          required
        },
        applicants: {
          $each: {
            title: {
              required
            },
            forename: {
              required
            },
            surname: {
              required
            },
            date_of_birth: {
              required,
              isDate,
              isNotInFuture
            },
            email: {
              email
            },
            telephone: {

            }
          }
        },
        property: {
          property_type: {
            required
          },
          bedrooms: {
            required
          },
          year_built: {
            numeric,
            required
          },
          address: {
            address1: {
              required
            },
            postcode: {
              required
            }
          }
        },
        correspondence_address: {
          address1: {
            required
          },
          postcode: {
            required
          }
        },
        optional_extras: {
          hec: {
            required
          },
          lah: {
            required
          }
        }
      };

      var non_standard_questions = {};

      if (this.model) {
        if (this.model.non_standard) {

          non_standard_questions = {
            non_standard: {
              construction_listed_bool: {
                required,
              },
              business_use_bool: {
                required,
              },
              occupancy_holiday_home_bool: {
                required,
              },
              building_work_bool: {
                required,
              },
              subsidence_bool: {
                required,
              },
              flooding_bool: {
                required,
              },
              criminal_conviction_bool: {
                required,
              },
              cancelled_declined_bool: {
                required,
              },
              claim_history_bool: {
                required,
              },
              construction_listed: {
                listed_building: {
                  required: requiredIf(function () {
                    return this.model.non_standard.construction_listed_bool
                  })
                },
                roof_type_question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.construction_listed_bool
                  })
                },
                roof_type: {
                  required: requiredIf(function () {
                    return this.model.non_standard.construction_listed_bool && this.model.non_standard.construction_listed.roof_type_question === true || this.model.non_standard.construction_listed.roof_type_question === 'true'
                  })
                },
                wall_type_question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.construction_listed_bool
                  })
                },
                wall_type: {
                  required: requiredIf(function () {
                    return this.model.non_standard.construction_listed_bool && this.model.non_standard.construction_listed.wall_type_question === true || this.model.non_standard.construction_listed.wall_type_question === 'true'
                  })
                },
                flat_roof: {
                  required: requiredIf(function () {
                    return this.model.non_standard.construction_listed_bool
                  })
                },
              },
              business_use: {
                question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.business_use_bool
                  })
                },
                business_type: {
                  required: requiredIf(function () {
                    return this.model.non_standard.business_use_bool && this.model.non_standard.business_use.question === true || this.model.non_standard.business_use.question === 'true'
                  })
                },
                business_visitors: {
                  required: requiredIf(function () {
                    return this.model.non_standard.business_use_bool && this.model.non_standard.business_use.question === true || this.model.non_standard.business_use.question === 'true'
                  })
                },
                business_stock_cover: {
                  numeric,
                  required: requiredIf(function () {
                    return this.model.non_standard.business_use_bool && this.model.non_standard.business_use.question === true || this.model.non_standard.business_use.question === 'true'
                  })
                }
              },
              occupancy_holiday_home: {
                question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.occupancy_holiday_home_bool
                  })
                },
                occupancy_type: {
                  required: requiredIf(function () {
                    return this.model.non_standard.occupancy_holiday_home_bool && this.model.non_standard.occupancy_holiday_home.question === true || this.model.non_standard.occupancy_holiday_home.question === 'true'
                  })
                },
                unoccupied_question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.occupancy_holiday_home_bool
                  })
                },
                unoccupied_period: {
                  required: requiredIf(function () {
                    return this.model.non_standard.occupancy_holiday_home_bool && this.model.non_standard.occupancy_holiday_home.unoccupied_question  === true || this.model.non_standard.occupancy_holiday_home.unoccupied_question === 'true' && this.model.non_standard.occupancy_holiday_home.unoccupied_question  === true
                  })
                },
                number_of_lodgers: {
                  required: requiredIf(function () {
                    return this.model.non_standard.occupancy_holiday_home_bool && this.model.non_standard.occupancy_holiday_home.question === true && this.model.non_standard.occupancy_holiday_home.occupancy_type === 4 || this.model.non_standard.occupancy_holiday_home.question === 'true' && this.model.non_standard.occupancy_holiday_home.occupancy_type === 4
                  })
                }
              },
              building_work: {
                question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.building_work_bool
                  })
                },
                building_work_type: {
                  required: requiredIf(function () {
                    return this.model.non_standard.subsidence_bool && this.model.non_standard.building_work.question === true || this.model.non_standard.building_work.question === 'true'
                  })
                },
                building_work_value: {
                  numeric,
                  required: requiredIf(function () {
                    return this.model.non_standard.subsidence_bool && this.model.non_standard.building_work.question === true || this.model.non_standard.building_work.question === 'true'
                  })
                },
                building_work_occupancy: {
                  required: requiredIf(function () {
                    return this.model.non_standard.subsidence_bool && this.model.non_standard.building_work.question === true || this.model.non_standard.building_work.question === 'true'
                  })
                }
              },
              subsidence: {
                subsidence_occured: {
                  required: requiredIf(function () {
                    return this.model.non_standard.subsidence_bool
                  })
                },
                property_owned_period: {
                  required: requiredIf(function () {
                    return this.model.non_standard.subsidence_bool && this.model.non_standard.subsidence.subsidence_occured === true || this.model.non_standard.subsidence.subsidence_occured === 'true'
                  })
                }
              },
              claim_history: {
                required: requiredIf(function () {
                  return this.model.non_standard.claim_history_bool === true || this.model.non_standard.claim_history_bool === 'true'
                })
              },
              flooding: {
                question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.flooding_bool
                  })
                },
                property_flood_year: {
                  required: requiredIf(function () {
                    return this.model.non_standard.flooding_bool && this.model.non_standard.flooding.question === true || this.model.non_standard.flooding.question === 'true'
                  })
                },
                area_flood_year: {
                  required: requiredIf(function () {
                    return this.model.non_standard.flooding_bool && this.model.non_standard.flooding.question === true || this.model.non_standard.flooding.question === 'true'
                  })
                },
              },
              criminal_conviction: {
                required: requiredIf(function () {
                  return this.model.non_standard.criminal_conviction_bool === true || this.model.non_standard.criminal_conviction_bool === 'true'
                })
              },
              cancelled_declined: {
                question: {
                  required: requiredIf(function () {
                    return this.model.non_standard.cancelled_declined_bool
                  })
                },
                what_happened: {
                  required: requiredIf(function () {
                    return this.model.non_standard.cancelled_declined_bool && this.model.non_standard.cancelled_declined.question === true || this.model.non_standard.cancelled_declined.question === 'true'
                  })
                },
                reason: {
                  required: requiredIf(function () {
                    return this.model.non_standard.cancelled_declined_bool && this.model.non_standard.cancelled_declined.question === true || this.model.non_standard.cancelled_declined.question === 'true'
                  })
                },
              }
            }
          }
        }
      }

      var manual_endorsements = {};

      if (this.refer === true || this.refer === 'true') {

        manual_endorsements = {
            $each: {
              code: {
                required: requiredIf(function() {
                  return this.refer === true || this.refer === 'true'
                })
              }
            }
        }

      }

      if (this.covers) {

      if (this.covers.buildings_cover) {

        var buildings_validation = {
          buildings_cover: {
            cover_amount: {
              required
            },
            excess: {
              required
            },
            claim_free_years: {
              required
            },
            accidental_damage: {
              required
            }
          }
        }

      }

      var claims_validation = {};

      if (this.model.non_standard.claim_history) {

        claims_validation = {
          claims: {
            required: requiredIf(function(){
                  return this.model.non_standard.claim_history
            }),
            $each: {
              date: {
                required
              },
              type: {
                required
              },
              cost: {
                decimal,
                required
              },
              status: {
                required
              }
            }
          }
        }

      }

      var convictions_validation = {};

      if (this.model.non_standard.criminal_conviction) {

        convictions_validation = {
          convictions: {
            required: requiredIf(function(){
                  return this.model.non_standard.criminal_conviction
            }),
            $each: {
              pending: {
                required
              },
              date: {
                required
              },
              type: {
                required
              },
              sentence: {
                required
              },
              age: {
                required
              },
              employment: {
                required
              }
            }
          }
        }

      }

      if (this.covers.contents_cover) {

        var contents_validation = {
          contents_cover: {
            cover_amount: {
              required
            },
            excess: {
              required
            },
            claim_free_years: {
              required
            },
            accidental_damage: {
              required
            },
            specified_items: {
              $each: {
                type: {
                  required
                },
                description: {
                  required
                },
                value: {
                  decimal,
                  required
                }
              }
            },
            high_risk_items: {
              $each: {
                type: {
                  required
                },
                description: {
                  required
                },
                value: {
                  decimal,
                  required
                }
              }
            }
          }
        }
      }

        if (this.model.payment.annual) {

          var payment_validation = {
            payment: {
              account_name: {
                required
              },
              account_number: {
                numeric,
                required
              },
              sort_code: {
                numeric,
                required
              },
              annual: {
                required
              }
            }
          }

          var standard_inc_payment_validations = Object.assign({}, standard_validations, payment_validation);

          return { model: Object.assign(
            {}, standard_inc_payment_validations, contents_validation, buildings_validation, non_standard_questions,
              claims_validation, convictions_validation
            ),
            manual_endorsements
          }


        } else {

          var payment_monthly_validation = {
            payment: {
              account_name: {
                required
              },
              account_number: {
                numeric,
                required
              },
              sort_code: {
                numeric,
                required
              },
              annual: {
                required
              },
              direct_debit_months: {
                required
              }
            }
          };

          var standard_inc_payment_validations_2 = Object.assign({}, standard_validations, payment_monthly_validation);

          return { model: Object.assign(
            {}, standard_inc_payment_validations_2, contents_validation, buildings_validation, non_standard_questions,
              claims_validation, convictions_validation
            ),
            manual_endorsements
          }

        }

      }
    },
    methods: {
      changeStartDate() {

        var someDate = new Date(this.$v.model.start_date.$model);

        var year = someDate.getFullYear()

            if (leapYear(parseInt(year))) {
                var leap_year = true;
            } else {
                var leap_year = false;
            }

            if (leap_year) {
                var feb_end_day = 29;
            } else {
                var feb_end_day = 28;
            }

            if (someDate <= new Date(year, 2 - 1, feb_end_day)) {
                if (leap_year) {
                    var numberOfDaysToAdd = 365;
                } else {
                    var numberOfDaysToAdd = 364;
                }
            } else {
                if (leap_year) {
                    var numberOfDaysToAdd = 364;
                } else {
                    if (leapYear(parseInt(year) + 1)) {
                        var numberOfDaysToAdd = 365;
                    } else {
                        var numberOfDaysToAdd = 364;
                    }
                }
            }

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            someDate.setHours(0, 0, 0, 0)


            var dd = someDate.getDate();
            var mm = someDate.getMonth() + 1;
            var y = someDate.getFullYear();

          this.$v.model.end_date.$model = String(y) + '-' + String(mm) + '-' + String(dd)

      },
      toggleNonStandardOccupiedSection(event, section) {

          let question = this.model.non_standard.occupancy_holiday_home.question;
          let unoccupied_question = this.model.non_standard.occupancy_holiday_home.unoccupied_question;

        if (question === false && unoccupied_question === false ||  question === 'false' && unoccupied_question === 'false') {

            setTimeout(this.model.non_standard.occupancy_holiday_home_bool = !this.model.non_standard.occupancy_holiday_home_bool, 1000);
            this.model.non_standard[section].question = null
            this.model.non_standard[section].question = null
        }

      },
      toggleNonStandardSection(event, section) {

        if (event === false || event === 'false') {

            setTimeout(this.model.non_standard[section + '_bool'] = !this.model.non_standard[section + '_bool'], 1000);
            this.model.non_standard[section].question = null
        }

      },
      validateApplicantState(index, name) {
        if (this.$v.model.applicants) {
          if (this.$v.model.applicants.$each[index]) {
            if (this.$v.model.applicants.$each[index][name]) {
                const {$dirty, $error} = this.$v.model.applicants.$each[index][name];
                return $dirty ? !$error : null;
            }
          }
        }
      },
      validateItemState(type, index, name) {
        if (this.$v.model.contents_cover) {
          if (this.$v.model.contents_cover[type]) {
            if (this.$v.model.contents_cover[type].$each[index]) {
              if (this.$v.model.contents_cover[type].$each[index][name]) {
                const {$dirty, $error} = this.$v.model.contents_cover[type].$each[index][name];
                return $dirty ? !$error : null;
              }
            }
          }
        }
      },
      validateManualEndorsementState(index) {
        if (this.manual_endorsements) {
          if (this.manual_endorsements[index]) {
            if (this.manual_endorsements[index].code) {
              return Boolean(this.manual_endorsements[index].code);
            }
          }
        }
      },
      validateClaimItemState(index, name) {
        if (this.$v.model.claims) {
          if (this.$v.model.claims.$each[index]) {
            if (this.$v.model.claims.$each[index][name]) {
              const {$dirty, $error} = this.$v.model.claims.$each[index][name];
              return $dirty ? !$error : null;
            }
          }
        }
      },
      validateConvictionItemState(index, name) {
        if (this.$v.model.convictions) {
          if (this.$v.model.convictions.$each[index]) {
            if (this.$v.model.convictions.$each[index][name]) {
              const {$dirty, $error} = this.$v.model.convictions.$each[index][name];
              return $dirty ? !$error : null;
            }
          }
        }
      },
      validateState(name) {

        if (this.$v.model[name]) {
          const {$dirty, $error} = this.$v.model[name];
          return $dirty ? !$error : null;
        }
      },
      validateNestedState(key, name) {

        if (this.$v.model[key]) {
          if (this.$v.model[key][name]) {
            const { $dirty, $error } = this.$v.model[key][name];
            return $dirty ? !$error : null;
          }
        }
      },
      validateNestedNestedState(parent, key, name) {
        if (this.$v.model[parent]) {
          if (this.$v.model[parent][key]) {
            if (this.$v.model[parent][key][name]) {
              const {$dirty, $error} = this.$v.model[parent][key][name];
              return $dirty ? !$error : null;
            }
          }
        }
      },
      getIndex: function(index) {
        return parseInt(index) + 1
      },
      addEndorsement() {
        const end = {
          code: ""
        };
        this.model.manual_endorsements = true
        this.manual_endorsements.push(end);
        //this.$options.validations()
      },
      removeEndorsement(id) {
        this.$delete(this.manual_endorsements, id)
      },
      addApplicant() {
        const applicant = {
          primary: false,
          title: null,
          forename: "",
          surname: "",
          date_of_birth: ""
        };
        this.model.applicants.push(applicant);
        this.$options.validations()
      },
      removeApplicant() {
        this.$delete(this.model.applicants, 1);
        this.$options.validations()
      },
      addSpecifiedItem() {

        const item = {
          type: '',
          description: '',
          value: '',
        };
        this.model.contents_cover.specified_items.push(item)
        this.$options.validations()
      },
      deleteSpecifiedItem(id) {
        this.$delete(this.model.contents_cover.specified_items, id)
        this.$options.validations()
      },
      addClaimItem() {

        const item = {
          date: '',
          type: '',
          cost: '',
          status: ''
        };
        this.model.claims.push(item)
        this.$options.validations()
      },
      deleteClaimItem(id) {
        this.$delete(this.model.claims, id)
        this.$options.validations()
      },
      addConvictionItem() {

        const item = {
          pending: '',
          date: '',
          type: '',
          sentence: '',
          age: '',
          employment: ''
        };
        this.model.convictions.push(item)
        this.$options.validations()
      },
      deleteConvictionItem(id) {
        this.$delete(this.model.convictions, id)
        this.$options.validations()
      },
      addHighRiskItem() {

        const item = {
          type: '',
          description: '',
          value: '',
        };
        this.model.contents_cover.high_risk_items.push(item)
        this.$options.validations()
      },
      deleteHighRiskItem(id) {
        this.$delete(this.model.contents_cover.high_risk_items, id)
        this.$options.validations()
      },
      addressMatch() {
        if (this.extras.address_match) {
          this.model.property.address.address1 = this.model.correspondence_address.address1;
          this.model.property.address.address2 = this.model.correspondence_address.address2;
          this.model.property.address.address3 = this.model.correspondence_address.address3;
          this.model.property.address.address4 = this.model.correspondence_address.address4;
          this.model.property.address.postcode = this.model.correspondence_address.postcode;
        }
        if (this.extras.address_match === false || this.extras.address_match === 'false') {
          this.model.property.address.address1 = null;
          this.model.property.address.address2 = null;
          this.model.property.address.address3 = null;
          this.model.property.address.address4 = null;
          this.model.property.address.postcode = null;
        }
      },
      addressUpdate() {
        if (this.extras.address_match === true || this.extras.address_match === 'true') {
          this.model.property.address.address1 = this.model.correspondence_address.address1;
          this.model.property.address.address2 = this.model.correspondence_address.address2;
          this.model.property.address.address3 = this.model.correspondence_address.address3;
          this.model.property.address.address4 = this.model.correspondence_address.address4;
          this.model.property.address.postcode = this.model.correspondence_address.postcode;
        }
      },
      corresponenceAddressUpdate() {
        if (this.extras.address_match === true || this.extras.address_match === 'true') {
          this.model.correspondence_address.address1 = this.model.property.address.address1;
          this.model.correspondence_address.address2 = this.model.property.address.address2;
          this.model.correspondence_address.address3 = this.model.property.address.address3;
          this.model.correspondence_address.address4 = this.model.property.address.address4;
          this.model.correspondence_address.postcode = this.model.property.address.postcode;
        }
      },
      addCover(name) {

        const buildings_cover = {
          "excess": '',
          "cover_amount": '',
          "claim_free_years": '',
          "accidental_damage": false
        };

        const contents_cover = {
          "excess": '',
          "cover_amount": '',
          "claim_free_years": '',
          "accidental_damage": false,
          "unspecified_cover_amount": 0,
          "specified_items": [],
          "high_risk_items": []

        };

        if (name === 'contents_cover') {
          this.model.contents_cover = contents_cover;
          this.covers.contents_cover = true;
        } else if (name === 'buildings_cover') {
          this.model.buildings_cover = buildings_cover;
          this.covers.buildings_cover = true;
        }
        this.$options.validations()
      },
      removeCover(name) {

        const buildings_cover = {
          "excess": {},
          "cover_amount": {},
          "claim_free_years": {},
          "accidental_damage": false
        };

        const contents_cover = {
          "excess": {},
          "cover_amount": {},
          "claim_free_years": {},
          "accidental_damage": false,
          "unspecified_cover_amount": 0,
          "specified_items": [],
          "high_risk_items": []

        };

        if (name === 'high_risk_items') {
          this.model.contents_cover.high_risk_items = []
        } else if (name === 'specified_items') {
          this.model.contents_cover.specified_items = []
        } else if (name === 'contents_cover') {
          this.model.contents_cover = contents_cover;
          this.covers.contents_cover = false;
        } else if (name === 'buildings_cover') {
          this.model.buildings_cover = buildings_cover;
          this.covers.buildings_cover = false;
        }
        this.$options.validations()
      },
      notEmptyObject(someObject) {
        if (someObject === null || someObject === undefined) {
          return false
        } else if (Array.isArray(someObject)) {
          return Object.keys(someObject).length
        } else if (Object.keys(someObject)) {
          return Object.keys(someObject)
        }
      },
      removeOverridePremium() {
        this.overidden_premium = false
        this.override_premium = null
        this.remove_premium_override = false
      },
      toggleShowBreakdown() {
        this.show_breakdown = !this.show_breakdown
      },
      switchMTAStage(stage) {
        this.stage = stage;
      },
      percentCalc(previous, current) {
        const percent = (((parseFloat(previous) - parseFloat(current)) / parseFloat(previous)) * 100).toFixed(2);
        if (percent < 0) {
          return percent * 100
        } else {
          return percent
        }
      },
      stageText() {
        if (this.stage === "success") {
          return 'Adjusted Policy:'
        } else {
          return 'Adjusting Policy:'
        }

      },
      submitMTAQuote() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          if (!this.model.contents_cover && !this.model.buildings_cover) {
            return
          }

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = JSON.parse(JSON.stringify(this.model));

          for (let applicant in post_data.applicants) {

            let dob_string = post_data.applicants[applicant].date_of_birth;
            let split_dob = dob_string.split("/")

            let day = split_dob[0]
            let month = split_dob[1]
            let year = split_dob[2]

            post_data.applicants[applicant].date_of_birth = year + '-' + month + '-' + day

          }
          
          // Set Quote Type

          if (!this.covers.buildings_cover && this.covers.contents_cover) {

            // Contents
            delete post_data['buildings_cover'];
            post_data['quote_type'] = 3

          } else if (!this.covers.contents_cover && this.covers.buildings_cover) {

            // Buildings
            delete post_data['contents_cover'];
            post_data['quote_type'] = 2

          } else {

            // Buildings & Contents
            post_data['quote_type'] = 1

          }

          // Non Standard

          if (!this.model.non_standard.construction_listed_bool) {
            delete post_data['non_standard']['construction_listed']
          }

          if (!this.model.non_standard.business_use_bool) {
            delete post_data['non_standard']['business_use']
          } else {
            delete post_data['non_standard']['business_use']['question']
          }

          if (!this.model.non_standard.building_work_bool) {
            delete post_data['non_standard']['building_work']
          } else {
            delete post_data['non_standard']['building_work']['question']
          }

          if (!this.model.non_standard.occupancy_holiday_home_bool) {
            delete post_data['non_standard']['occupancy_holiday_home']
          } else {
            if (this.model.non_standard.occupancy_holiday_home.question  === false) {
              delete post_data['non_standard']['occupancy_holiday_home']['occupancy_type']
              delete post_data['non_standard']['occupancy_holiday_home']['number_of_lodgers']
            }
            if (this.model.non_standard.occupancy_holiday_home.unoccupied_question  === false) {
              delete post_data['non_standard']['occupancy_holiday_home']['unoccupied_period']
            }
            if (this.model.non_standard.occupancy_holiday_home.occupancy_type !== 4) {
              delete post_data['non_standard']['occupancy_holiday_home']['number_of_lodgers']
            }

          }

          if (!this.model.non_standard.subsidence_bool) {
            delete post_data['non_standard']['subsidence']
          } else {
            delete post_data['non_standard']['subsidence']['question']
          }

          if (!this.model.non_standard.flooding_bool) {
            delete post_data['non_standard']['flooding']
          } else {
            delete post_data['non_standard']['flooding']['question']
          }

          if (!this.model.non_standard.criminal_conviction_bool) {
            delete post_data['non_standard']['criminal_conviction']
          }

          if (!this.model.non_standard.claim_history_bool) {
            delete post_data['non_standard']['claim_history']
            delete post_data['claims']
          }

          if (!this.model.non_standard.cancelled_declined_bool) {
            delete post_data['non_standard']['cancelled_declined']
          } else {
            delete post_data['non_standard']['cancelled_declined']['question']
          }

          // Refer

          if (this.refer) {

            if (this.manual_endorsements.length === 0 && !this.model.contents_load && !this.model.buildings_load && !this.model.premium) {
              return
            }

            if (this.manual_endorsements.length > 0) {
              let manual_endorsements_list = [];
              this.manual_endorsements.forEach(function (end) {
                manual_endorsements_list.push(end.code)
              });

              Object.assign(post_data, {
                'manual_endorsements': true,
                'manual_endorsements_list': manual_endorsements_list
              })
            }

            if (this.covers.buildings_cover && parseFloat(this.model.buildings_load) > 0) {
              Object.assign(post_data, {
                'load_override': true,
                'buildings_load': this.model.buildings_load
              })
            }

            if (this.covers.contents_cover && parseFloat(this.model.contents_load) > 0) {
              Object.assign(post_data, {
                'load_override': true,
                'contents_load': this.model.contents_load
              })
            }

            if (parseFloat(this.model.premium) > 0) {
              Object.assign(post_data, {
                'premium_override': true,
                'premium': this.model.premium
              })
            }

          }
          if (post_data['non_standard']) {
            delete post_data['non_standard']['construction_listed_bool']
            delete post_data['non_standard']['business_use_bool']
            delete post_data['non_standard']['building_work_bool']
            delete post_data['non_standard']['occupancy_holiday_home_bool']
            delete post_data['non_standard']['subsidence_bool']
            delete post_data['non_standard']['flooding_bool']
            delete post_data['non_standard']['criminal_conviction_bool']
            delete post_data['non_standard']['claim_history_bool']
            delete post_data['non_standard']['cancelled_declined_bool']
          }

          let url = '/mta/?product=' + localStorage.getItem('selected_product');

          axios.post(url, post_data).then(
            response => {
              this.quote_response = response.data;
              this.error = false;

              this.refer = response.data.insurers[0].refer;
              this.refer_heading = "The changes have resulted in a refer.";
              this.refer_reason = response.data.insurers[0].refer_reason;

              if (this.refer === true) {
                this.stage = "refer";
              } else {
                this.stage = "premium";
              }

            }
          ).catch(error => {
              console.log('post error');
              console.log(error.response);

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }

              // Decline logic

              if (error.response.status === 400) {

                if (error.response.data) {

                  if (error.response.data.insurer) {

                    // Decline

                    if (error.response.data.insurer.decline) {
                      this.error_heading = 'These changes have resulted in a Decline';
                      this.error_text = error.response.data.insurer.reason;
                      this.stage = 'decline';
                      window.scrollTo(document.querySelector('#decline').offsetTop, 100);
                    }

                  }
                } else {

                  this.error = true;
                  this.error_heading = 'There was a problem';
                  this.error_text = 'Please try and re-submit the form';
                  this.stage = 'quote';

                }

              }

          });

        }

      },
      submitPremiumOverride() {

        this.error = false;

        if (this.override_premium > 0) {
          this.error = false;

          const data = {
            "policy_number": this.model.policy_number,
            "premium": this.override_premium

          };

          let url = '/mta/override/?product=' + localStorage.getItem('selected_product');

          axios.post(url, data).then(res => {
            this.overidden_premium_response = res.data;

            this.overidden_premium = true
            this.remove_premium_override = true
          }).catch(error => {
            console.log('premium error');
            console.log(error)
            this.overidden_premium = false
            this.remove_premium_override = true
            this.error = true
            this.error_heading = 'There was a problem'
            this.error_text = 'Please try and re-submit the override premium form'
          })

        } else {
          console.log('error premium 0')
        }

      },
      submitMTAConfirm() {

        let  put_data = {};

        if (this.overidden_premium === 'true' || this.overidden_premium === true) {

          put_data = {
            policy_number: this.model.policy_number,
            premium_override: true,
            premium: this.override_premium,
            manual_endorsements: false
          };

        } else {

          put_data = {
            policy_number: this.model.policy_number,
            premium_override: false,
            manual_endorsements: false
          };

        }

        let url = '/mta/?product=' + localStorage.getItem('selected_product');

        axios.put(url, put_data).then(
          response => {
            this.success_text = this.model.policy_number + ' - Policy has been updated.';
            this.extras.version += 1;
            this.extras.created_at = 'Now';
            this.stage = 'success';
            this.error = false;
          }
        ).catch(error => {

            // Unauthorised
            if (error.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('post error');
            console.log(error.response)
            this.error = true
            this.error_heading = 'There was a problem'
            this.error_text = 'Please try and re-submit the form'
        });

      }
    },
    beforeCreate() {
      let url = '/mta/' + this.$route.params.id + '/?product=' + localStorage.getItem('selected_product');
      axios.get(url).then( //  + this.$route.params.id + '/'
          response => {
            this.model = response.data.data;
            this.options = response.data.options;
            this.extras = response.data.extras;
            this.covers = response.data.covers;
            this.previous_premium_response = response.data.current_premium;
            this.policy_loaded = true;
            this.previous_underwriter_premium = response.data.current_underwriter_premium;
            this.refer = response.data.refer;
            this.previous_refer = response.data.previous_refer;
            this.previous_refer_data = response.data.previous_refer_data;
            if (this.refer) {
              this.stage = 'refer'
            }
            this.refer_reason = response.data.refer_reason;
            this.all_endorsements = response.data.all_endorsements;
            this.existing_endorsements = response.data.endorsements;
            this.$options.validations()
          }
        ).catch(error => {

            // Unauthorised
            if (error.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('get error');
            console.log(error)
        });

    },
  }

</script>

<style scoped>

  .btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-left: 5px;
  }

  .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-right: 6px;
  }

  .hand-cursor {
    cursor: pointer;
  }

</style>
